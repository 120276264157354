import React from 'react';
import { Provider } from 'react-redux';

import configureStore from 'store';
import OrdersView from 'views/Orders';

const store = configureStore();

function App() {
  return (
    <Provider store={store}>
      <OrdersView />
    </Provider>
  );
}

export default App;
