import find from 'lodash/find';
import findIndex from 'lodash/findIndex';

export const types = {
  INIT: 'ORDERS/INIT',
  FETCH_ERROR: 'ORDERS/FETCH_ERROR',
  FETCH_LOADING: 'ORDERS/FETCH_LOADING',
  FETCH_SUCCESS: 'ORDERS/FETCH_SUCCESS',
  ORDER_DELETE: 'ORDERS/ORDER_DELETE',
  ORDER_CANCEL_EDIT: 'ORDERS/ORDER_CANCEL_EDIT',
  ORDER_EDIT: 'ORDERS/ORDER_EDIT',
  ORDER_UPDATE: 'ORDERS/ORDER_UPDATE',
  FETCH_RATES: 'ORDERS/FETCH_RATES',
  PURCHASE_RATE: 'ORDERS/PURCHASE_RATE',
};

export const initialState = {
  edit: null,
  unshipped: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SUCCESS: {
      return {
        ...state,
        unshipped: action.orders,
      };
    }
    case types.ORDER_CANCEL_EDIT: {
      return {
        ...state,
        edit: null,
      };
    }
    case types.ORDER_DELETE: {
      const matchIndex = findIndex(state.unshipped, { order_id: action.id });
      return {
        ...state,
        unshipped: [...state.unshipped.slice(0, matchIndex), ...state.unshipped.slice(matchIndex + 1)],
      };
    }
    case types.ORDER_EDIT: {
      const match = find(state.unshipped, { order_id: action.id });
      return {
        ...state,
        edit: match,
      };
    }
    case types.ORDER_UPDATE: {
      return {
        ...state,
        edit: null,
        unshipped: state.unshipped.map((order) => {
          if (order.order_id !== action.order.order_id) return order;
          return action.order;
        }),
      };
    }
    default:
      return state;
  }
};

export const actions = {
  init: () => ({ type: types.INIT }),
  fetchError: () => ({ type: types.FETCH_ERROR }),
  fetchLoading: () => ({ type: types.FETCH_LOADING }),
  fetchSuccess: (orders) => ({ type: types.FETCH_SUCCESS, orders }),
  orderCancelEdit: (id) => ({ type: types.ORDER_CANCEL_EDIT, id }),
  orderDelete: (id) => ({ type: types.ORDER_DELETE, id }),
  orderEdit: (id) => ({ type: types.ORDER_EDIT, id }),
  orderUpdate: (order) => ({ type: types.ORDER_UPDATE, order }),
  fetchRates: (order) => ({ type: types.FETCH_RATES, order }),
  purchaseRate: (order, rate) => ({ type: types.PURCHASE_RATE, order, rate }),
};

export const getOrders = (state) => state.orders.unshipped;
export const getEditOrder = (state) => state.orders.edit;
