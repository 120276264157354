import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { actions as ordersActions } from 'reducers/orders';

import reducers from 'reducers';
import sagas from 'sagas';

const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [
    sagaMiddleware,
  ];

  const disableLogger = ['production', 'test'];
  if (!disableLogger.includes(process.env.NODE_ENV)) {
    middlewares.push(createLogger({
      collapsed: (getState, action, logEntry) => !logEntry.error,
    }));
  }

  const store = createStore(
    reducers,
    applyMiddleware(...middlewares),
  );

  sagaMiddleware.run(sagas);

  if (process.env.NODE_ENV !== 'test') {
    // Fire initialization actions
    store.dispatch(ordersActions.init());
  }

  return store;
};

export default configureStore;
