import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { actions, getEditOrder } from 'reducers/orders';

export default function EditShippingDialog() {
  const dispatch = useDispatch();
  const order = useSelector(getEditOrder);
  const [name, setName] = useState(order?.['shipping_name']);
  const [address1, setAddress1] = useState(order?.['shipping_address_1']);
  const [address2, setAddress2] = useState(order?.['shipping_address_2']);
  const [city, setCity] = useState(order?.['shipping_city']);
  const [state, setState] = useState(order?.['shipping_state']);
  const [postalCode, setPostalCode] = useState(order?.['shipping_postal_code']);
  const [country, setCountry] = useState(order?.['shipping_country']);

  useEffect(() => {
    setName(order?.['shipping_name']);
    setAddress1(order?.['shipping_address_1']);
    setAddress2(order?.['shipping_address_2']);
    setCity(order?.['shipping_city']);
    setState(order?.['shipping_state']);
    setPostalCode(order?.['shipping_postal_code']);
    setCountry(order?.['shipping_country']);
  }, [order]);

  const open = !!order;

  const handleChange = (e) => {
    const { target: { value, id } } = e;
    switch (id) {
      case 'address1':
        setAddress1(value);
        break;
      case 'address2':
        setAddress2(value);
        break;
      case 'city':
        setCity(value);
        break;
      case 'country':
        setCountry(value);
        break;
      case 'name':
        setName(value);
        break;
      case 'postalCode':
        setPostalCode(value);
        break;
      case 'state':
        setState(value);
        break;
      default:
        console.warn('Unknown change', e);
    }
  };

  const handleClose = () => {
    dispatch(actions.orderCancelEdit());
  };

  const handleSave = () => {
    const update = {
      ...order,
      shipping_name: name,
      shipping_address_1: address1,
      shipping_address_2: address2,
      shipping_city: city,
      shipping_state: state,
      shipping_postal_code: postalCode,
      shipping_country: country,
    };
    dispatch(actions.orderUpdate(update));
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      {order && (
        <>
          <DialogTitle id="form-dialog-title">Edit Shipping Address</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              value={name}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              margin="dense"
              id="address1"
              label="Address 1"
              value={address1}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              margin="dense"
              id="address2"
              label="Address 2"
              value={address2}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              margin="dense"
              id="city"
              label="City"
              value={city}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              margin="dense"
              id="state"
              label="State"
              value={state}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              margin="dense"
              id="postalCode"
              label="Postal Code"
              value={postalCode}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              margin="dense"
              id="country"
              label="Country"
              value={country}
              onChange={handleChange}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
