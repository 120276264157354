import { apiBasePath } from 'config';

const createFetchOptions = (opts = {}) => {
  const { method = 'GET', body } = opts;

  const options = {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (body !== undefined) options.body = JSON.stringify(body);

  return options;
};

const request = (path, options) => {
  const fetchOptions = createFetchOptions(options);
  return fetch(`${apiBasePath}/${path}`, fetchOptions).then((response) => response.json());
};

const fetchUnshippedOrders = () => request('orders');
const fetchShippingRates = (body) => request('shipping/rates', { method: 'POST', body });
const purchaseShipping = (body) => request('shipping/purchase', { method: 'POST', body });

export {
  fetchUnshippedOrders,
  fetchShippingRates,
  purchaseShipping,
};
