import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { actions, types } from 'reducers/orders';

import { fetchShippingRates, fetchUnshippedOrders, purchaseShipping } from 'helpers/api';

export function* initHandler() {
  try {
    yield put(actions.fetchLoading());
    const orders = yield call(fetchUnshippedOrders);
    yield put(actions.fetchSuccess(orders));
  } catch (e) {
    yield put(actions.fetchError());
  }
}

export function* fetchRatesHandler(action) {
  try {
    const rates = yield call(fetchShippingRates, action.order);
    yield put(actions.orderUpdate({
      ...action.order,
      shipment_id: rates.id,
      shipment_rates: rates.rates,
    }));
  } catch (e) {
    console.error('fuck', e);
  }
}

export function* purchaseRateHandler(action) {
  try {
    const shipment = yield call(purchaseShipping, { ...action.order, shipment_rate_id: action.rate });

    delete action.order.shipment_id;
    delete action.order.shipment_rates;
    delete action.order.shipment_rate_id;

    yield put(actions.orderUpdate({
      ...action.order,
      shipment_tracking: shipment.tracking_code,
      shipment_label: shipment.postage_label.label_url,
    }));
  } catch (e) {
    console.error('fuck', e);
  }
}

export function* watchOrdersActions() {
  yield takeLatest(types.INIT, initHandler);
  yield takeLatest(types.FETCH_RATES, fetchRatesHandler);
  yield takeLatest(types.PURCHASE_RATE, purchaseRateHandler);
}

export default [
  fork(watchOrdersActions),
];
