import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
// import GetAppIcon from '@material-ui/icons/GetApp';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import { actions } from 'reducers/orders';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  input: {
    verticalAlign: 'baseline',
    margin: 10,
    width: 40,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
}));

export default function Row({ row }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [dirty, setDirty] = useState(false);
  const [rate, setRate] = useState(row?.['shipment_rate_id'] ?? '');
  const [dimensions, setDimensions] = useState({
    parcelLength: row?.['parcel_length'],
    parcelWidth: row?.['parcel_width'],
    parcelHeight: row?.['parcel_height'],
    parcelWeight: row?.['parcel_weight'],
  });

  useEffect(() => {
    setDimensions({
      parcelLength: row?.['parcel_length'],
      parcelWidth: row?.['parcel_width'],
      parcelHeight: row?.['parcel_height'],
      parcelWeight: row?.['parcel_weight'],
    });

    setRate(row?.['shipment_rate_id'] ?? '');
  }, [row]);

  const updateStore = useCallback(debounce((d, r) => {
    const parcel = {
      parcel_length: d.parcelLength,
      parcel_width: d.parcelWidth,
      parcel_height: d.parcelHeight,
      parcel_weight: d.parcelWeight,
    };
    const order = { ...r, ...parcel };
    dispatch(actions.orderUpdate(order));
    if (!some(parcel, isEmpty)) dispatch(actions.fetchRates(order));
  }, 1000), []);

  useEffect(() => {
    if (dirty) {
      updateStore(dimensions, row);
      setDirty(false);
    }
  }, [dirty, dimensions, updateStore, row]);

  const handleChange = (e) => {
    const { target: { value, id } } = e;
    setDimensions({ ...dimensions, [id]: value });
    setDirty(true);
  };

  const handleDelete = () => {
    dispatch(actions.orderDelete(row.order_id));
  };

  const handleEdit = () => {
    dispatch(actions.orderEdit(row.order_id));
  };

  // const handleRateFetch = () => {
  //   dispatch(actions.fetchRates(row));
  // };

  const handleRateChange = (e) => {
    const { target: { value } } = e;
    setRate(value);
  };

  const handleRatePurchase = () => {
    dispatch(actions.purchaseRate(row, rate));
  };

  // const handleLabelDownload = () => {
  //   console.log('handleLabelDownload', rate, row);
  // };

  const rates = row?.['shipment_rates'] ?? [];
  const disableRatesSelect = !rates.length;
  // const disableRatesFetch = !dimensions?.parcelLength || !dimensions?.parcelWidth
  //   || !dimensions?.parcelHeight || !dimensions?.parcelWeight;

  return (
    <TableRow key={row.order_id}>
      <TableCell>
        <div>{new Date(row.order_date).toLocaleString()}</div>
        <div>{row.store}</div>
        <div>{row.order_id}</div>
      </TableCell>
      <TableCell>
        <div>{row.customer_name}</div>
        <div>{row.customer_email}</div>
      </TableCell>
      <TableCell>
        <div className={classes.flexRow}>
          <div>
            <IconButton aria-label="Edit" className={classes.margin} size="small" onClick={handleEdit}>
              <EditIcon fontSize="inherit" />
            </IconButton>
          </div>
          <div>
            <div>{row.shipping_name}</div>
            <div>{row.shipping_address_1}</div>
            <div>{row.shipping_address_2}</div>
            <div>{row.shipping_city}, {row.shipping_state}</div>
            <div>{row.shipping_postal_code} {row.shipping_country}</div>
          </div>
        </div>
      </TableCell>
      <TableCell>
        <TextField
          autoFocus
          margin="none"
          size="small"
          id="parcelLength"
          label="L"
          value={dimensions?.parcelLength}
          onChange={handleChange}
          type="number"
          className={classes.input}
        />x
        <TextField
          margin="none"
          size="small"
          id="parcelWidth"
          label="W"
          value={dimensions?.parcelWidth}
          onChange={handleChange}
          type="number"
          className={classes.input}
        />x
        <TextField
          margin="none"
          size="small"
          id="parcelHeight"
          label="H"
          value={dimensions?.parcelHeight}
          onChange={handleChange}
          type="number"
          className={classes.input}
        />
      </TableCell>
      {/* <TableCell>{row.parcel_width || 0} x {row.parcel_length || 0} x {row.parcel_height || 0}</TableCell> */}
      <TableCell>
        <TextField
          margin="none"
          size="small"
          id="parcelWeight"
          label="Oz"
          value={dimensions?.parcelWeight}
          onChange={handleChange}
          type="number"
          className={classes.input}
        />
      </TableCell>
      <TableCell>
        <FormControl className={classes.formControl}>
          <InputLabel id="rates-select-label">Rates</InputLabel>
          <Select
            labelId="rates-select-label"
            id="rates-select"
            value={rate}
            onChange={handleRateChange}
            disabled={disableRatesSelect}
          >
            {rates.map((r) => (<MenuItem value={r.id} key={r.id}>${r.rate} - {r.service}</MenuItem>))}
          </Select>
        </FormControl>
        {row.shipment_tracking && (<div><a href={row.shipment_label} download>{row.shipment_tracking}</a></div>)}
      </TableCell>
      <TableCell>
        <IconButton aria-label="Purchase Rate" className={classes.margin} size="medium" onClick={handleRatePurchase} disabled={!rate}>
          <ShoppingCartIcon fontSize="inherit" />
        </IconButton>
        {/* <IconButton aria-label="Download Label" className={classes.margin} size="medium" onClick={handleLabelDownload} disabled={true}>
          <GetAppIcon fontSize="inherit" />
        </IconButton> */}
        <IconButton aria-label="Delete" className={classes.margin} size="medium" onClick={handleDelete}>
          <DeleteIcon fontSize="inherit" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

Row.defaultProps = {
  row: {},
};

Row.propTypes = {
  row: PropTypes.object,
};
