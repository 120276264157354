import React from 'react';
import OrdersTable from 'components/OrdersTable';

export default function Orders() {
  return (
    <>
      <OrdersTable />
    </>
  );
}
