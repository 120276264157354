import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { getOrders } from 'reducers/orders';

import OrderRow from './Row';
import EditShippingDialog from './EditShippingDialog';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  table: {
    minWidth: 650,
  },
}));

export default function OrdersTable() {
  const classes = useStyles();
  const orders = useSelector(getOrders);

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Order</TableCell>
              <TableCell>Customer</TableCell>
              <TableCell>Shipping Address</TableCell>
              <TableCell>Dimensions (in)</TableCell>
              <TableCell>Weight (oz)</TableCell>
              <TableCell>Shipment</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => (
              <OrderRow row={order} key={order.order_id} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EditShippingDialog />
    </>
  );
}
